import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-pharmacy',
  templateUrl: './add-pharmacy.component.html',
  styleUrls: ['./add-pharmacy.component.scss'],
})
export class AddPharmacyComponent implements OnInit {
  pharmacyForm: FormGroup;
  isEditing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddPharmacyComponent>,
    private pharmacyService: PharmacyService
  ) {}

  ngOnInit(): void {
    this.pharmacyForm = new FormGroup({
      pharmacyName: new FormControl('', [Validators.required]),
      uniquePharmacyIdentifier: new FormControl('', [Validators.required]),
      pharmacyIdentityInformation: new FormControl('', [Validators.required]),
      pharmacyPhoneNumber: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      provinceRegion: new FormControl('', [Validators.required]),
      addressNumber: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      // ... add other form controls as needed
    });
  }

  save(): void {
    this.pharmacyForm.markAllAsTouched(); // Add this line
    if (this.pharmacyForm.valid) {
      this.isEditing = false;
      this.pharmacyService.add(this.pharmacyForm.value).subscribe((res) => {
        console.log(res);
        this.dialogRef.close();
      });
    } else {
      Swal.fire({
        title: 'Missing fields',
        text: 'Please fill all the required fields',
        confirmButtonText: 'Ok',
      });
    }
  }

  cancel(): void {
    this.isEditing = false;
    this.dialogRef.close();
  }

  back(): void {
    this.isEditing = false;
    this.dialogRef.close();
  }
}
