import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './screens/login/login.component';
import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login.guard';
import { PharmacistDetailsComponent } from './screens/pharmacist-details/pharmacist-details.component';
import { PharmaciesTableComponent } from './screens/landing-page/landingPageComponents/pharmacies-table/pharmacies-table.component';
import { PharmacistsTableComponent } from './screens/landing-page/landingPageComponents/pharmacists-table/pharmacists-table.component';
import { PharmacyDetailsComponent } from './screens/pharmacy-details/pharmacy-details.component';
import { PhysiciansTableComponent } from './screens/landing-page/landingPageComponents/physicians-table/physicians-table.component';
import { PhysicianDetailsComponent } from './screens/physician-details/physician-details.component';
import { IssueCertificatesTableComponent } from './screens/landing-page/landingPageComponents/issue-certificates-table/issue-certificates-table.component';
import { OperatorGuard } from './services/guards/operatorGuard';
import { UsersTableComponent } from './screens/landing-page/landingPageComponents/users-table/users-table.component';
import { UserDetailsComponent } from './screens/user-details/user-details.component';
import { AdminGuard } from './services/guards/adminGuard';
import { UserGuard } from './services/guards/userGuard';
import { CombinedOperatorGuard } from './services/guards/combinedOperatorGuard';
import { CombinedUserOperatorGuard } from './services/guards/combinedUserOperatorGuard';
import { TermsConditionsComponent } from './screens/terms-conditions/terms-conditions.component';
import { MyProfileComponent } from './screens/landing-page/landingPageComponents/my-profile/my-profile.component';
import { PharmacistDetailsViewComponent } from './components/pharmacist-details-view/pharmacist-details-view.component';
import { PharmacyDetailsEditComponent } from './components/pharmacy-details-edit/pharmacy-details-edit.component';
import { PhysicianDetailsViewComponent } from './components/physician-details-view/physician-details-view.component';

const routes: Routes = [
  { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: 'landing-page',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'terms-conditions', component: TermsConditionsComponent },
      {
        path: 'users',
        component: UsersTableComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'pharmacists',
        component: PharmacistsTableComponent,
        canActivate: [CombinedOperatorGuard],
      },
      {
        path: 'pharmacist',
        component: PharmacistDetailsComponent,
        canActivate: [CombinedOperatorGuard],
      },
      {
        path: 'pharmacies',
        component: PharmaciesTableComponent,
        canActivate: [CombinedOperatorGuard],
      },
      {
        path: 'pharmacy',
        component: PharmacyDetailsComponent,
        canActivate: [CombinedOperatorGuard],
      },
      {
        path: 'user',
        component: UserDetailsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'physicians',
        component: PhysiciansTableComponent,
        canActivate: [OperatorGuard],
      },
      {
        path: 'physician',
        component: PhysicianDetailsComponent,
        canActivate: [OperatorGuard],
      },
      {
        path: 'certificates',
        component: IssueCertificatesTableComponent,
        canActivate: [CombinedUserOperatorGuard],
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
      },
      {
        path: 'pharmacist-details',
        component: PharmacistDetailsViewComponent,
        canActivate: [CombinedUserOperatorGuard],
      },
      {
        path: 'pharmacy-details-edit',
        component: PharmacyDetailsEditComponent,
        canActivate: [CombinedUserOperatorGuard],
      },
      {
        path: 'physician-details-view',
        component: PhysicianDetailsViewComponent,
        canActivate: [CombinedUserOperatorGuard],
      },
    ],
  },

  { path: '**', redirectTo: '/landing-page', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
