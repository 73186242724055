import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CyLoginService } from 'src/app/auth/service/cyLogin.service';
import { environment } from '../../../environments/environment';

export interface Claim {
  type: string;
  value: string;
}

export type Session = Claim[] | null;
const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginUrl = environment.production
    ? `${environment.APP_URl}bff/login?returnUrl=/landing-page`
    : '/bff/login?returnUrl=/landing-page';

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: CyLoginService,
  ) {}

  ngOnInit() {}

  login() {
    this.router.navigate(['/bff/login']);
  }
}
