import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { IUser } from '../../../../interfaces/IUser';
import { UserService } from '../../../../services/user.service';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { MatDialog } from '@angular/material/dialog';
import { AddOperatorComponent } from 'src/app/components/add-operator/add-operator.component';
import { OperatorService } from 'src/app/services/operator.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent {
  formGroup!: FormGroup;
  isAddNewActive: boolean = false;
  displayedColumns: string[] = [
    'nationalId',
    'name',
    'surname',
    'email',
    'role',
    'isActive',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: any;
  isLoadingResults = true;
  resultsLength: number = 0;
  selectedRow: IUser;
  userRole: string;

  constructor(
    private dataService: UserService,
    private authService: LandingPageService,
    private operatorService: OperatorService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.authService.currentUserRole.subscribe((role) => {
      this.userRole = role;
      console.log('Role', role);
    });
    this.operatorService.OnChange.subscribe((success) => {
      if (success) {
        this.loadData(dataService);
        Swal.fire({
          title: 'Operator Created',
          text: 'Operator created successfully.',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Failed',
          text: "Can't create operator. Please try again.",
          icon: 'error',
        });
      }
    });
    this.loadData(dataService);
  }
  private loadData(dataService: UserService) {
    dataService.list().subscribe((response) => {
      const users = response.filter(
        (user) =>
          user.role === 'pharmacist' ||
          user.role === 'pharmacy-operator' ||
          user.role === 'operator',
      );
      this.data = new MatTableDataSource(users);
      this.isLoadingResults = false;
      this.resultsLength = users.length;
      this.data.sort = this.sort;
    });
  }

  openAddOperatorDialog(): void {
    console.log('Opening add pharmacist dialog');
    this.dialog.open(AddOperatorComponent, {
      width: '70vw',
      height: '70vh', // Adjust the size as needed
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
    console.log(filterValue);
  }

  rowClicked(row: IUser) {
    this.selectedRow = row;
    console.log(row);
    this.router.navigate(['/landing-page/user'], { state: row });
  }
}
