// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-modal-button:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRlbGV0ZS1tb2RhbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU9BO0VBQ0ksZUFBQTtBQU5KIiwiZmlsZSI6ImRlbGV0ZS1tb2RhbC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXHJcbi5lZGl0LW1vZGFsLWJ1dHRvbntcclxuICAgIC8vIGNvbG9yOiAjM0Y1MUI1O1xyXG4gIFxyXG4gICAgXHJcbn1cclxuXHJcbi5lZGl0LW1vZGFsLWJ1dHRvbjpob3ZlcntcclxuICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICAgICAgICBcclxufVxyXG5cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/delete-modal/delete-modal.scss"],"names":[],"mappings":"AAOA;EACI,eAAA;AANJ;AACA,obAAob","sourcesContent":["\r\n.edit-modal-button{\r\n    // color: #3F51B5;\r\n  \r\n    \r\n}\r\n\r\n.edit-modal-button:hover{\r\n    cursor: pointer;\r\n        \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
