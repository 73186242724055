// role.guard.ts
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { LandingPageService } from '../landing-page.service';
import { OperatorGuard } from './operatorGuard';
import { OperatorPharmacyGuard } from './operatorPharmacyGuard';

@Injectable({
  providedIn: 'root',
})
export class CombinedOperatorGuard implements CanActivate {
  constructor(
    private operatorGuard: OperatorGuard,
    private operatorPharmacyGuard: OperatorPharmacyGuard
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    console.log('Combined operator guard');
    console.log(
      this.operatorGuard
        .canActivate(next, state)
        .subscribe((s) => console.log(s))
    );
    console.log(
      this.operatorPharmacyGuard
        .canActivate(next, state)
        .subscribe((s) => console.log(s))
    );
    return combineLatest([
      this.operatorGuard.canActivate(next, state),
      this.operatorPharmacyGuard.canActivate(next, state),
    ]).pipe(
      map(([operatorResult, pharmacyResult]) => {
        console.log('Operator Guard Result:', operatorResult);
        console.log('Pharmacy Guard Result:', pharmacyResult);
        return operatorResult || pharmacyResult;
      })
    );
  }
}
