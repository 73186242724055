import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IPharmacist } from 'src/app/interfaces/IPharmacist';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { PharmacistService } from 'src/app/services/pharmacist.service';

@Component({
  selector: 'app-pharmacist-details',
  templateUrl: './pharmacist-details.component.html',
  styleUrls: ['./pharmacist-details.component.scss'],
})
export class PharmacistDetailsComponent {
  pharmacist: IPharmacist;
  pharmacistForm: FormGroup;
  isEditing = false;
  initialState: any;
  userRole: string;
  roles = ['pharmacist', 'operator-pharmacy'];
  isSaving = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dataService: PharmacistService,
    private authService: LandingPageService
  ) {
    if (router.getCurrentNavigation() == null) {
      router.navigate(['/landing-page/pharmacists']);
      return;
    }
    this.pharmacist = router.getCurrentNavigation()!.extras
      .state as IPharmacist;

    if (this.pharmacist) {
      this.userRole = this.pharmacist.role
        ? this.pharmacist.role
        : 'pharmacist';
    } else {
      this.userRole = 'pharmacist';
    }

    console.log(this.pharmacist);
    this.pharmacistForm = this.formBuilder.group({
      email: [this.pharmacist.email],
      id: [this.pharmacist.id],
      userId: [this.pharmacist.userId],
      idNumber: [this.pharmacist.idNumber],
      mobilePhone: [this.pharmacist.mobilePhone],
      name: [this.pharmacist.name],
      pharmacistNeededInfo: [this.pharmacist.pharmacistNeededInfo],
      pharmacistRegistrationNumber: [
        this.pharmacist.pharmacistRegistrationNumber,
      ],
      surname: [this.pharmacist.surname],
      isActive: [this.pharmacist.isActive],
      role: [this.userRole],
    });
    this.pharmacistForm.get('role')?.valueChanges.subscribe((selectedRole) => {
      this.onRoleChange(selectedRole);
    });

    this.initialState = this.pharmacistForm.value;
    this.pharmacistForm.disable();
    this.pharmacistForm.get('role')?.enable();
    this.pharmacistForm.get('isActive')?.enable();
  }
  onRoleChange(selectedRole: string) {
    // Function to run when role changes
    if (selectedRole !== this.userRole) {
      this.isSaving = true;
      this.dataService
        .ChangeRole(+this.pharmacist.userId, selectedRole)
        .subscribe((res: any) => {
          // I want to check if it's 200 response write me the code:
          console.log(res);
          console.log('Role changed to:', selectedRole);
          this.isSaving = false;
        });
      // delay 2 seconds

      console.log('Selected role:', selectedRole);
    }
    // Add your logic here
  }
  updatePharmacistStatus(event: any) {
    console.log(this.pharmacist);

    this.pharmacist.isActive = event.checked;
    this.dataService
      .ActivationPharmacist(this.pharmacist.id, this.pharmacist.isActive)
      .subscribe((response) => {
        console.log(response);
      });
  }
  edit() {
    this.isEditing = true;
    this.pharmacistForm.enable();
  }

  cancel() {
    this.isEditing = false;
    this.pharmacistForm.disable();
    this.pharmacistForm.setValue(this.initialState);
  }

  save() {
    this.isEditing = false;
    this.pharmacistForm.disable();
    this.initialState = this.pharmacistForm.value;
    this.dataService.update(this.pharmacistForm.value).subscribe((response) => {
      console.log(response);
    });
    // Here you might want to send the form data to a server, etc.
  }
  back() {
    console.log('Back button clicked!');
    this.router.navigate(['/landing-page/pharmacists']);
  }
}
