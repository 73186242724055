import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IPhysician } from '../../../../interfaces/IPhysician';
import { PhysicianService } from 'src/app/services/physician.service';
import { MatSort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-physicians-table',
  templateUrl: './physicians-table.component.html',
  styleUrls: ['./physicians-table.component.scss'],
})
export class PhysiciansTableComponent {
  formGroup!: FormGroup;
  isAddNewActive: boolean = false;
  displayedColumns: string[] = [
    'idNumber',
    'email',
    'name',
    'surname',
    'dateOfBirth',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: any;
  isLoadingResults = true;
  resultsLength: number = 0;
  selectedRow: IPhysician;
  constructor(private dataService: PhysicianService, private router: Router) {
    dataService.list().subscribe((response) => {
      console.log(response);
      this.data = new MatTableDataSource(response);
      this.isLoadingResults = false;
      this.resultsLength = response.length;
      this.data.sort = this.sort;
      this.data.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
    console.log(filterValue);
  }

  rowClicked(row: IPhysician) {
    this.selectedRow = row;
    console.log(row);
    this.router.navigate(['/landing-page/physician-details-view'], {
      state: row,
    });
  }
}
