import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPharmacist } from 'src/app/interfaces/IPharmacist';
import { PharmacistService } from 'src/app/services/pharmacist.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pharmacist-details-view',
  templateUrl: './pharmacist-details-view.component.html',
  styleUrl: './pharmacist-details-view.component.scss',
})
export class PharmacistDetailsViewComponent implements OnInit {
  @Input() pharmacistDetails: IPharmacist;
  specialtiesList: string = '';
  userRole: string;
  roles = ['pharmacist', 'operator-pharmacy'];
  constructor(
    private location: Location,
    private dataService: PharmacistService,
    private router: Router
  ) {
    this.pharmacistDetails = router.getCurrentNavigation()!.extras
      .state as IPharmacist;
    if (this.pharmacistDetails) {
      this.userRole = this.pharmacistDetails.role
        ? this.pharmacistDetails.role
        : 'pharmacist';
    } else {
      this.userRole = 'pharmacist';
    }
  }
  ngOnInit(): void {
    this.pharmacistDetails = this.pharmacistDetails;
  }
  updatePharmacistStatus(event: any) {
    console.log(this.pharmacistDetails);

    this.pharmacistDetails.isActive = event.checked;
    this.dataService
      .ActivationPharmacist(
        this.pharmacistDetails.id,
        this.pharmacistDetails.isActive
      )
      .subscribe((response: any) => {
        console.log(response);
      });
  }
  goBack() {
    this.location.back();
  }
}
