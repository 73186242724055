import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { NotFoundComponent } from './screens/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { PhysiciansTableComponent } from './screens/landing-page/landingPageComponents/physicians-table/physicians-table.component';
import { PharmaciesTableComponent } from './screens/landing-page/landingPageComponents/pharmacies-table/pharmacies-table.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { PharmacistsTableComponent } from './screens/landing-page/landingPageComponents/pharmacists-table/pharmacists-table.component';
import { PharmacistService } from './services/pharmacist.service';
import { PharmacyService } from './services/pharmacy.service';
import { PhysicianService } from './services/physician.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { EditModalComponent } from './components/edit-modal/edit-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { UpdateDialogComponent } from './components/dialogs/update-dialog/update-dialog.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog';
import { DialogComponent } from './components/dialogs/Dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { MatDividerModule } from '@angular/material/divider';

import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthenticationModule } from './auth/auth.module';
import { TableHeadingComponent } from './components/table-heading/table-heading.component';
import { IssueCertificatesTableComponent } from './screens/landing-page/landingPageComponents/issue-certificates-table/issue-certificates-table.component';
import { IssueCertificateService } from './services/issueCertificateService.service';
import { RevokeCertificateService } from './services/revokeCertificateService.service';
import { LandingPageService } from './services/landing-page.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PharmacistDetailsComponent } from './screens/pharmacist-details/pharmacist-details.component';
import { PharmacyDetailsComponent } from './screens/pharmacy-details/pharmacy-details.component';
import { PhysicianDetailsComponent } from './screens/physician-details/physician-details.component';
import { DatePipe } from '@angular/common';
import { UsersTableComponent } from './screens/landing-page/landingPageComponents/users-table/users-table.component';
import { UserService } from './services/user.service';
import { UserDetailsComponent } from './screens/user-details/user-details.component';
import { AddPharmacistComponent } from './components/add-pharmacist/add-pharmacist.component';
import { AddPharmacyComponent } from './components/add-pharmacy/add-pharmacy.component';
import { AddOperatorComponent } from './components/add-operator/add-operator.component';
import { OperatorService } from './services/operator.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from './interfaces/DateFormats';
import { DevelopmentService } from './services/development.service';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './components/footer/footer.component';
import { MyProfileComponent } from './screens/landing-page/landingPageComponents/my-profile/my-profile.component';
import { StatusComponent } from './components/status/status.component';
import { PhysicianUserInfoComponent } from './components/physician-user-info/physician-user-info.component';
import { PharmacistUserInfoComponent } from './components/pharmacist-user-info/pharmacist-user-info.component';
import { PharmacistDetailsViewComponent } from './components/pharmacist-details-view/pharmacist-details-view.component';
import { PharmacyDetailsEditComponent } from './components/pharmacy-details-edit/pharmacy-details-edit.component';
import { PhysicianDetailsViewComponent } from './components/physician-details-view/physician-details-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingPageComponent,
    NotFoundComponent,
    HeaderComponent,
    NotFoundComponent,
    UsersTableComponent,
    PhysiciansTableComponent,
    PharmacistsTableComponent,
    PharmaciesTableComponent,
    EditModalComponent,
    UpdateDialogComponent,
    DeleteModalComponent,
    DeleteDialogComponent,
    DialogComponent,
    TableHeadingComponent,
    IssueCertificatesTableComponent,
    PharmacistDetailsComponent,
    PharmacyDetailsComponent,
    PhysicianDetailsComponent,
    UserDetailsComponent,
    AddPharmacistComponent,
    AddPharmacyComponent,
    AddOperatorComponent,
    MyProfileComponent,
    StatusComponent,
    PhysicianUserInfoComponent,
    PharmacistUserInfoComponent,
    PharmacyDetailsEditComponent,
    PharmacistDetailsViewComponent,
    PhysicianDetailsViewComponent,
  ],
  imports: [
    MatSelectModule,
    MatMomentDateModule,
    MatCheckboxModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    NoopAnimationsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatSortModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatDividerModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-center' }),
    MatTooltipModule,
    MatMenuModule,
    DragDropModule,
    AuthenticationModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FooterComponent,
    MatIconModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    DevelopmentService,
    OperatorService,
    UserService,
    PharmacistService,
    PharmacyService,
    PhysicianService,
    AppComponent,
    IssueCertificateService,
    RevokeCertificateService,
    LandingPageService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
