// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dilog-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Three equal columns */
  grid-gap: 5px; /* Gap between grid items */
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.mdc-dialog__actions {
  display: flex;
  justify-content: flex-end !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRpYWxvZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksV0FBQTtFQUNBLGFBQUE7RUFDQSw4QkFBQSxFQUFBLHdCQUFBO0VBQ0EsYUFBQSxFQUFBLDJCQUFBO0FBQ0o7O0FBRUU7RUFDRSwrQkFBQTtBQUNKOztBQUNFO0VBQ0UsYUFBQTtFQUNBLG9DQUFBO0FBRUoiLCJmaWxlIjoiZGlhbG9nLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGlsb2ctd3JhcHBlciB7XHJcbiAgICB3aWR0aDogMTAwJTtcclxuICAgIGRpc3BsYXk6IGdyaWQ7XHJcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAxZnI7IC8qIFRocmVlIGVxdWFsIGNvbHVtbnMgKi9cclxuICAgIGdyaWQtZ2FwOiA1cHg7IC8qIEdhcCBiZXR3ZWVuIGdyaWQgaXRlbXMgKi9cclxuICB9XHJcbiAgXHJcbiAgLm1hdC1tZGMtZGlhbG9nLWFjdGlvbnMge1xyXG4gICAganVzdGlmeS1jb250ZW50OiBlbmQgIWltcG9ydGFudDtcclxuICB9XHJcbiAgLm1kYy1kaWFsb2dfX2FjdGlvbnMge1xyXG4gICAgZGlzcGxheTogZmxleDtcclxuICAgIGp1c3RpZnktY29udGVudDogZmxleC1lbmQgIWltcG9ydGFudDtcclxuICB9XHJcbiAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/dialog.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA,EAAA,wBAAA;EACA,aAAA,EAAA,2BAAA;AACJ;;AAEE;EACE,+BAAA;AACJ;;AACE;EACE,aAAA;EACA,oCAAA;AAEJ;AACA,41BAA41B","sourcesContent":[".dilog-wrapper {\r\n    width: 100%;\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr; /* Three equal columns */\r\n    grid-gap: 5px; /* Gap between grid items */\r\n  }\r\n  \r\n  .mat-mdc-dialog-actions {\r\n    justify-content: end !important;\r\n  }\r\n  .mdc-dialog__actions {\r\n    display: flex;\r\n    justify-content: flex-end !important;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
