import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { filter } from 'rxjs';
import { IPharmacist } from 'src/app/interfaces/IPharmacist';
import { IUserProfile } from 'src/app/interfaces/IUserProfile';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss', '../../../styles.scss'],
})
export class LandingPageComponent implements OnInit {
  userRole = '';
  isLoading = true;
  acceptedTermsAndConditions = false;
  userProfile: IUserProfile;
  isLandingPage: boolean;
  selectedTab = 'pharmacists'; // Default selected tab

  constructor(private router: Router, private authService: LandingPageService) {
    this.isLoading = true;
    this.router.events.subscribe(() => {
      this.checkIfLandingPage();
    });
    this.checkIfLandingPage();
    this.authService.userProfile.subscribe((profile) => {
      this.userProfile = profile;
      this.authService.acceptTermsAndConditions.subscribe((accepted) => {
        this.acceptedTermsAndConditions = accepted;
      });
      this.authService.currentUserRole.subscribe((role) => {
        this.userRole = role;
      });
      if (profile.isGDPR === false || profile.isTermsAccepted === false) {
        this.router.navigate(['/landing-page/terms-conditions']);
        this.isLoading = false;
      } else {
        this.authService.acceptTermsAndConditions.next(true);

        this.authService.currentUserRole.subscribe((role) => {
          if (role === 'administrator-neha') {
            this.router.navigate(['/landing-page/users']);
          } else if (role === 'operator') {
            this.router.navigate(['/landing-page/pharmacists']);
          } else if (role === 'operator-pharmacy') {
            console.log('navigating to pharmacies');
            this.router.navigate(['/landing-page/pharmacists']);
          } else {
            this.router.navigate(['/landing-page/certificates']);
          }
          this.isLoading = false;
        });
      }
    });
  }

  ngOnInit(): void {}
  checkIfLandingPage() {
    this.isLandingPage =
      this.router.url === '/landing-page/pharmacists' ||
      this.router.url === '/landing-page/physicians' ||
      this.router.url === '/landing-page/certificates' ||
      this.router.url === '/landing-page/pharmacies';
  }
  userHasRole(requiredRole: string | string[]): boolean {
    if (requiredRole instanceof Array) {
      return (
        requiredRole.filter(
          (r) => r.toLocaleLowerCase() === this.userRole.toLocaleLowerCase()
        ).length > 0
      );
    } else {
      return (
        requiredRole.toLocaleLowerCase() === this.userRole.toLocaleLowerCase()
      );
    }
  }

  selectTab(tab: string) {
    console.log('Event', tab);
    this.selectedTab = tab;

    switch (tab.trim().toLocaleLowerCase()) {
      case 'pharmacists':
        if (
          this.userRole === 'operator' ||
          this.userRole === 'operator-pharmacy'
        ) {
          console.log('navigating to pharmacist');
          this.router.navigate(['/landing-page/pharmacists']);
        }

        break;
      case 'physicians':
        if (this.userRole === 'operator')
          this.router.navigate(['/landing-page/physicians']);
        break;
      case 'pharmacies':
        if (
          this.userRole === 'operator' ||
          this.userRole === 'operator-pharmacy'
        )
          this.router.navigate(['/landing-page/pharmacies']);
        break;
      case 'certificates':
        this.router.navigate(['/landing-page/certificates']);
        console.log('navigating to certificates');

        break;
      case 'my profile':
        console.log('navigating to my profile');
        console.log(JSON.stringify(this.userProfile));
        this.router.navigate(['/landing-page/my-profile'], {
          state: this.userProfile,
        });
        break;
      default:
        console.log('No matching tab');
        console.log(tab.trim());
        break;
      // Add cases for other tabs as needed
    }
  }
}
