import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CyLoginService } from './service/cyLogin.service';
import { LandingPageService } from '../services/landing-page.service';
import { map, of, switchMap } from 'rxjs';

export const LoginGuard: CanActivateFn = () => {
  const isUserLoggedIn = inject(CyLoginService);
  const router = inject(Router);
  const landingPageService = inject(LandingPageService);
  const res = isUserLoggedIn.getIsAuthenticated().pipe(
    switchMap((authenticated: boolean) => {
      if (authenticated) {
        return landingPageService.HasEror404.pipe(
          map((value: any) => {
            if (!value) {
              router.navigate(['/landing-page/pharmacists']);
              return false;
            }
            return true;
          })
        );
      } else {
        return of(true);
      }
    })
  );
  return res;
};
