import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, map, Observable, of, shareReplay } from 'rxjs';

const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;
@Injectable()
export class CyLoginService {
  private session$: Observable<Session> | null = null;

  currentHasSession = this.getIsAuthenticated().pipe(
    map((value) => {
      return value;
    }),
  );

  constructor(private http: HttpClient) {}

  public login() {}

  public getIsAuthenticated(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(map(UserIsAuthenticated));
  }

  public getSession(ignoreCache: boolean = false) {
    const headers = new HttpHeaders({
      'X-CSRF': '1',
    });
    if (!this.session$ || ignoreCache) {
      this.session$ = this.http.get<Session>('bff/user', { headers }).pipe(
        catchError((err) => {
          console.log(err);
          return of(ANONYMOUS);
        }),
        shareReplay(CACHE_SIZE),
      );
    }
    return this.session$;
  }

  public getLogoutUrl(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(
        (s) =>
          s.find((c) => c.type === 'bff:logout_url')?.value +
          '&returnUrl=/api/logged-out',
      ),
    );
  }

  public getUsername(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map((s) => s.find((c) => c.type === 'name')?.value),
    );
  }
}
export interface Claim {
  type: string;
  value: string;
}
export type Session = Claim[] | null;
function UserIsAuthenticated(s: Session): s is Claim[] {
  return s !== null;
}
