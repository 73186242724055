import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPhysician } from '../interfaces/IPhysician';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';

@Injectable()
export class PhysicianService extends TableService<IPhysician> {
  constructor(
    private http: HttpClient,
    private accessToken: GetAccessToken,
  ) {
    super(http, accessToken);
  }

  ActivationPhysician(physicianID: string, isEnabled: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken.getToken()}`,
    });
    return this.http.patch(
      `${environment.BACKEND_URL}Physician/ActivationPhysician/?physicianID=${physicianID}&activate=${isEnabled}`,
      {},
      { headers },
    );
  }

  override get getURL(): string {
    return `${environment.BACKEND_URL}Physician/GetPhysicians`;
  }

  override get deleteURL(): string {
    return `${environment.BACKEND_URL}Physician/DeletePhysician`;
  }

  override get addURL(): string {
    return `${environment.BACKEND_URL}Physician/CreatePhysicianAccount`;
  }

  override get updateURL(): string {
    return `${environment.BACKEND_URL}Physician/UpdatePhysician`;
  }
}
