import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IPharmacy } from 'src/app/interfaces/IPharmacy';
import { PharmacyService } from 'src/app/services/pharmacy.service';

@Component({
  selector: 'app-pharmacy-details',
  templateUrl: './pharmacy-details.component.html',
  styleUrls: ['./pharmacy-details.component.scss'],
})
export class PharmacyDetailsComponent {
  pharmacy: IPharmacy;
  pharmacyForm: FormGroup;
  isEditing = false;
  initialState: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dataService: PharmacyService,
  ) {
    if (router.getCurrentNavigation() == null) {
      router.navigate(['/landing-page/pharmacies']);
      return;
    }
    this.pharmacy = router.getCurrentNavigation()!.extras.state as IPharmacy;

    this.pharmacyForm = this.formBuilder.group({
      id: [this.pharmacy.id],
      pharmacyIdentityInformation: [this.pharmacy.pharmacyIdentityInformation],
      uniquePharmacyIdentifier: [this.pharmacy.uniquePharmacyIdentifier],
      pharmacyName: [this.pharmacy.pharmacyName],
      pharmacyPhoneNumber: [this.pharmacy.pharmacyPhoneNumber],
      address: [this.pharmacy.address],
      addressNumber: [this.pharmacy.addressNumber],
      postcode: [this.pharmacy.postcode],
      provinceRegion: [this.pharmacy.provinceRegion],
      city: [this.pharmacy.city],
    });

    this.initialState = this.pharmacyForm.value;
    this.pharmacyForm.disable();
  }
  formatLabel(value: any) {
    return value;
  }
  edit() {
    this.isEditing = true;
    this.pharmacyForm.enable();
  }

  cancel() {
    this.isEditing = false;
    this.pharmacyForm.disable();
    this.pharmacyForm.setValue(this.initialState);
  }

  save() {
    this.isEditing = false;
    this.pharmacyForm.disable();
    this.initialState = this.pharmacyForm.value;
    this.dataService.update(this.pharmacyForm.value).subscribe((response) => {
      console.log(response);
    });
    // Here you might want to send the form data to a server, etc.
  }

  back() {
    console.log('Back button clicked!');
    this.router.navigate(['/landing-page/pharmacies']);
  }
  leftColumnFields() {
    const fields = Object.keys(this.pharmacyForm.controls);
    return fields.slice(0, fields.length / 2).map((key) => {
      return { key: key, value: this.pharmacyForm.controls[key] };
    });
  }

  rightColumnFields() {
    const fields = Object.keys(this.pharmacyForm.controls);
    return fields.slice(fields.length / 2).map((key) => {
      return { key: key, value: this.pharmacyForm.controls[key] };
    });
  }
}
