// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dilog-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Three equal columns */
  grid-gap: 5px; /* Gap between grid items */
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.mdc-dialog__actions {
  display: flex;
  justify-content: flex-end !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwZGF0ZS1kaWFsb2cuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQUE7RUFDQSxhQUFBO0VBQ0EsOEJBQUEsRUFBQSx3QkFBQTtFQUNBLGFBQUEsRUFBQSwyQkFBQTtBQUNGOztBQUVBO0VBQ0UsK0JBQUE7QUFDRjs7QUFDQTtFQUNFLGFBQUE7RUFDQSxvQ0FBQTtBQUVGIiwiZmlsZSI6InVwZGF0ZS1kaWFsb2cuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kaWxvZy13cmFwcGVyIHtcclxuICB3aWR0aDogMTAwJTtcclxuICBkaXNwbGF5OiBncmlkO1xyXG4gIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmcjsgLyogVGhyZWUgZXF1YWwgY29sdW1ucyAqL1xyXG4gIGdyaWQtZ2FwOiA1cHg7IC8qIEdhcCBiZXR3ZWVuIGdyaWQgaXRlbXMgKi9cclxufVxyXG5cclxuLm1hdC1tZGMtZGlhbG9nLWFjdGlvbnMge1xyXG4gIGp1c3RpZnktY29udGVudDogZW5kICFpbXBvcnRhbnQ7XHJcbn1cclxuLm1kYy1kaWFsb2dfX2FjdGlvbnMge1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZCAhaW1wb3J0YW50O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/update-dialog/update-dialog.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA,EAAA,wBAAA;EACA,aAAA,EAAA,2BAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AACA;EACE,aAAA;EACA,oCAAA;AAEF;AACA,40BAA40B","sourcesContent":[".dilog-wrapper {\r\n  width: 100%;\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr; /* Three equal columns */\r\n  grid-gap: 5px; /* Gap between grid items */\r\n}\r\n\r\n.mat-mdc-dialog-actions {\r\n  justify-content: end !important;\r\n}\r\n.mdc-dialog__actions {\r\n  display: flex;\r\n  justify-content: flex-end !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
