import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete-dialog/delete-dialog';




@Component({
  selector: 'delete-modal',
  templateUrl: 'delete-modal.html',
  styleUrls: ['delete-modal.scss']
})
export class DeleteModalComponent<T> {
  @Input({ required: true}) data!: T;
  @Output() dataToParent:EventEmitter<object> = new EventEmitter();

  constructor(public dialog: MatDialog) {
    
   
    
  }

  openDialog(): void {
    
    const dialogRef = this.dialog.open(DeleteDialogComponent,{
      data:this.data
    });

    dialogRef.afterClosed().subscribe(result => {           
      if(typeof result !== 'undefined') {
        this.dataToParent.emit(result)
      }
      
    });
  }
}

