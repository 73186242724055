import { Component, OnInit, ViewChild } from '@angular/core';
import { IPharmacist } from '../../../../interfaces/IPharmacist';
import { PharmacistService } from 'src/app/services/pharmacist.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LandingPageService } from '../../../../services/landing-page.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPharmacistComponent } from '../../../../components/add-pharmacist/add-pharmacist.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pharmacists-table',
  templateUrl: './pharmacists-table.component.html',
  styleUrls: ['./pharmacists-table.component.scss'],
})
export class PharmacistsTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedRow: IPharmacist;
  dialogSubscription: Subscription;
  displayedColumns: string[] = [
    'position',
    'email',
    'pharmacistNeededInfo',
    'name',
    'surname',
    'idNumber',
    'role',
    'mobilePhone',
    'pharmacistRegistrationNumber',
  ];
  isLoadingResults = true;
  data: any;
  resultsLength: number = 0;
  userRole: string;
  constructor(
    private authService: LandingPageService,
    private dataService: PharmacistService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.authService.currentUserRole.subscribe((role) => {
      this.userRole = role;
      console.log('Role', role);
    });
  }
  ngOnInit(): void {
    this.dataService.list().subscribe((response) => {
      console.log(response);
      this.data = new MatTableDataSource(response);
      this.isLoadingResults = false;
      this.resultsLength = response.length;
      this.data.sort = this.sort;
    });
  }

  openAddPharmacistDialog(): void {
    console.log('Opening add pharmacist dialog');
    this.dialogSubscription = this.dialog.afterAllClosed.subscribe((res) => {
      this.dialogSubscription.unsubscribe();
      console.log('The dialog was closed');
      this.dataService.list().subscribe((response) => {
        console.log(response);
        this.data = new MatTableDataSource(response);
        this.isLoadingResults = false;
        this.resultsLength = response.length;
        this.data.sort = this.sort;
      });
    });
    this.dialog.open(AddPharmacistComponent, {
      width: '70vw',
      height: '80vh', // Adjust the size as needed
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
    console.log(filterValue);
  }

  rowClicked(row: IPharmacist) {
    this.selectedRow = row;
    console.log(row);
    this.router.navigate(['/landing-page/pharmacist-details'], { state: row });
  }
}
