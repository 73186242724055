import { Component, Inject } from "@angular/core";
import {  MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";




@Component({
    selector: 'update-dialog',
    templateUrl: 'update-dialog.html',
    styleUrls: ['./update-dialog.scss']
    
  })
  export class UpdateDialogComponent {
    inputValues: Map<string, string> = new Map(); 
    displayedColumns: string[] = [
      'id',
      'pharmacyIdentityInformation',
      'uniquePharmacyIdentifier',
      'pharmacyName',
      'pharmacyPhoneNumber',
      'address',
      'addressNumber',
      'postcode',
      'provinceRegion',
      'city',
      'country',
      'actions',
    ];

    constructor(
      public dialogRef: MatDialogRef<UpdateDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      
      this.data.columns.forEach((item:string) => {
        this.inputValues.set(item, ''); 
      });


      this.inputValues.set('id',this.data.data.id)
      
    }
    inputValueChanged(value: string, key: string): void {
      this.inputValues.set(key, value); 
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  }