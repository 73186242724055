import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient } from '@angular/common/http';
import { IPharmacy } from '../interfaces/IPharmacy';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';

@Injectable()
export class PharmacyService extends TableService<IPharmacy> {
  constructor(
    private http: HttpClient,
    private accessToken: GetAccessToken,
  ) {
    super(http, accessToken);
  }
  override get getURL(): string {
    return `${environment.BACKEND_URL}Pharmacy/GetPharmacies`;
  }
  override get updateURL(): string {
    return `${environment.BACKEND_URL}Pharmacy/UpdatePharmacy`;
  }

  override get deleteURL(): string {
    return `${environment.BACKEND_URL}Pharmacy/DeletePharmacy`;
  }

  override get addURL(): string {
    return `${environment.BACKEND_URL}Pharmacy/CreatePharmacyAccount`;
  }
}
