import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-heading',
  templateUrl: './table-heading.component.html',
  styleUrls: ['./table-heading.component.scss']
})
export class TableHeadingComponent {
 @Input({ required: true }) headerTitle :string;
 @Input({ required: true }) isAddNewActive :boolean;
 @Input({ required: true }) isEditable :boolean;
 @Input({ required: true }) applyFilter: (event: Event) => void;
 @Input({ required: true }) addRow: () => void;
 
 constructor(){}
}
