import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { UpdateDialogComponent } from '../dialogs/update-dialog/update-dialog.component';



@Component({
  selector: 'edit-modal',
  templateUrl: 'edit-modal.html',
  styleUrls: ['edit-modal.scss']
})
export class EditModalComponent<T> {
  @Input({ required: true}) columns!: T;
  @Input() items:any;
  @Output() dataToParent:EventEmitter<object> = new EventEmitter();

  constructor(public dialog: MatDialog) {

    
    
    
  }



  openDialog(): void {
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      data:{columns:this.columns , data:this.items},
    });

    dialogRef.afterClosed().subscribe(result => {

      
      if(typeof result !== 'undefined') {

        const obj = Object.fromEntries(result);

        const finalData:any= {};

        Object.keys(obj).forEach(key => {
          const trimmedKey = key.replace(/^\s+|\s+$/g, ''); 
          const concatenatedKey = trimmedKey.replace(/\s+/g, '');
          finalData[concatenatedKey] = obj[key];
        });
        this.dataToParent.emit(finalData)
      }
      
    });
  }
}

