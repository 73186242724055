import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';

import { CyLoginService } from './service/cyLogin.service';
import { GetAccessToken } from './service/get-access.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './service/auth.interceptor';
@NgModule({
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CyLoginService,
    GetAccessToken,
  ],
})
export class AuthenticationModule {}
