import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPharmacist } from '../interfaces/IPharmacist';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';
import { IOperator } from '../interfaces/IOperator';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DevelopmentService {
  OnChange = new Subject<boolean>();
  constructor(private http: HttpClient, private accessToken: GetAccessToken) {}

  ChangeRole(userId: number, role: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken.getToken()}`,
    });
    return this.http.post(
      `${environment.BACKEND_URL}Development/ChangeLoginRole?userId=${userId}&role=${role}  `,
      {},
      { headers }
    );
  }
}
