import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IPhysician } from 'src/app/interfaces/IPhysician';
import { PhysicianService } from 'src/app/services/physician.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-physician-details',
  templateUrl: './physician-details.component.html',
  styleUrls: ['./physician-details.component.scss'],
})
export class PhysicianDetailsComponent {
  physician: IPhysician;
  physicianForm: FormGroup;
  isEditing = false;
  initialState: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dataService: PhysicianService,
    private datePipe: DatePipe
  ) {
    if (router.getCurrentNavigation() == null) {
      router.navigate(['/landing-page/physicians']);
      return;
    }
    this.physician = router.getCurrentNavigation()!.extras.state as IPhysician;

    this.physicianForm = this.formBuilder.group({
      userId: [this.physician.userId],
      id: [this.physician.id],
      email: [this.physician.email],
      name: [this.physician.name],
      surname: [this.physician.surname],
      dateOfBirth: [
        this.datePipe.transform(
          this.physician.dateOfBirth,
          'dd/MM/yyyy',
          'GMT'
        ),
      ],
      idNumber: [this.physician.idNumber],
      specialties: [this.physician.specialties.join(', ')],
      physicianRegistrationNumber: [this.physician.physicianRegistrationNumber],
      mobilePhone: [this.physician.mobilePhone],
      officeLandlinePhone: [this.physician.officeLandlinePhone],
      address: [this.physician.address],
      addressNumber: [this.physician.addressNumber],
      postcode: [this.physician.postcode],
      provinceRegion: [this.physician.provinceRegion],
      city: [this.physician.city],
      country: [this.physician.country],
      isActive: [this.physician.isActive],
    });

    this.initialState = this.physicianForm.value;
    this.physicianForm.disable();
  }

  edit() {
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.physicianForm.disable();
    this.physicianForm.setValue(this.initialState);
  }
  updatePhysicianStatus(event: any) {
    console.log(this.physician);

    this.physician.isActive = event.checked;
    this.dataService
      .ActivationPhysician(this.physician.id, this.physician.isActive)
      .subscribe((response) => {
        console.log(response);
      });
  }

  back() {
    console.log('Back button clicked!');
    this.router.navigate(['/landing-page/physicians']);
  }
}
