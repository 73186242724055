import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPharmacist } from '../interfaces/IPharmacist';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';

@Injectable()
export class PharmacistService extends TableService<IPharmacist> {
  constructor(private http: HttpClient, private accessToken: GetAccessToken) {
    super(http, accessToken);
  }
  ActivationPharmacist(pharmacistID: string, isEnabled: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken.getToken()}`,
    });
    return this.http.patch(
      `${environment.BACKEND_URL}Pharmacist/ActivationPharmacist/?pharmacistID=${pharmacistID}&activate=${isEnabled}`,
      {},
      { headers }
    );
  }
  ChangeRole(userId: number, role: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken.getToken()}`,
    });
    return this.http.post(
      `${environment.BACKEND_URL}operator/AssignOperatorPharmacist?userId=${userId}&role=${role}  `,
      {},
      { headers }
    );
  }

  override get getURL(): string {
    return `${environment.BACKEND_URL}Pharmacist/GetPharmacists`;
  }
  override get updateURL(): string {
    return `${environment.BACKEND_URL}Pharmacist/UpdatePharmacist`;
  }

  override get deleteURL(): string {
    return `${environment.BACKEND_URL}Pharmacist/ActivationPharmacist`;
  }

  override get addURL(): string {
    return `${environment.BACKEND_URL}Pharmacist/CreatePharmacistAccount`;
  }
}
