import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient } from '@angular/common/http';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';
import { IUser } from "../interfaces/IUser";

@Injectable()
export class UserService extends TableService<IUser> {
  constructor(private http: HttpClient, private accessToken: GetAccessToken) {
    super(http, accessToken);
  }

  override get getURL(): string {
    return `${environment.BACKEND_URL}User/GetUsers`;
  }

  get addURL(): string {
    return "";
  }

  get deleteURL(): string {
    return "";
  }

  get updateURL(): string {
    return "";
  }
}
