// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-modal-button {
  color: #3F51B5;
}

.edit-modal-button:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVkaXQtbW9kYWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNJLGNBQUE7QUFBSjs7QUFLQTtFQUNJLGVBQUE7QUFGSiIsImZpbGUiOiJlZGl0LW1vZGFsLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuLmVkaXQtbW9kYWwtYnV0dG9ue1xyXG4gICAgY29sb3I6ICMzRjUxQjU7XHJcbiAgXHJcbiAgICBcclxufVxyXG5cclxuLmVkaXQtbW9kYWwtYnV0dG9uOmhvdmVye1xyXG4gICAgY3Vyc29yOiBwb2ludGVyO1xyXG4gICAgICAgIFxyXG59XHJcblxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/edit-modal/edit-modal.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;;AAKA;EACI,eAAA;AAFJ;AACA,wcAAwc","sourcesContent":["\r\n.edit-modal-button{\r\n    color: #3F51B5;\r\n  \r\n    \r\n}\r\n\r\n.edit-modal-button:hover{\r\n    cursor: pointer;\r\n        \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
