import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUserProfile } from '../interfaces/IUserProfile';
import { GetAccessToken } from '../auth/service/get-access.service';

@Injectable()
export class LandingPageService {
  public HasEror404 = new BehaviorSubject<boolean>(false);
  private userRole = new BehaviorSubject<string>('');
  // initialize the behavior subject with the default value empty object
  userProfile = new BehaviorSubject<IUserProfile>({} as IUserProfile);

  currentUserRole = this.userRole.asObservable();
  acceptTermsAndConditions: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private _http: HttpClient,
    private accessToken: GetAccessToken,
  ) {
    const savedRole = sessionStorage.getItem('userRole');
    if (savedRole) {
      this.userRole.next(savedRole);
    }
    const savedProfile = sessionStorage.getItem('userProfile');
    if (savedProfile) {
      this.userProfile.next(JSON.parse(savedProfile));
    }
  }
  changeUserRole(role: string) {
    sessionStorage.setItem('userRole', role);
    this.userRole.next(role);
  }
  getUserRole(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http
      .get<any>(`${environment.BACKEND_URL}User/GetRole`, {
        headers: headers,
      })
      .pipe(
        map((res) => {
          const role = res.role as string;
          this.changeUserRole(role);
          return role;
        }),
      );
  }
  getUserProfile(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http
      .get<any>(`${environment.BACKEND_URL}User/GetProfile`, {
        headers: headers,
      })
      .pipe(
        map((res) => {
          console.log(res);
          const data = res as IUserProfile;
          sessionStorage.setItem('userProfile', JSON.stringify(data));
          this.userProfile.next(data);
          return res;
        }),
      );
  }
  acceptTermsAndConditionsFunc() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(this.accessToken.getToken2);
    return this._http
      .patch<any>(`${environment.BACKEND_URL}User/AcceptTermsAndGDPR`, {
        headers: headers,
      })
      .pipe(
        map((res) => {
          console.log(res.data);
          sessionStorage.setItem('GDPR', JSON.stringify(res.data));
          return res.data;
        }),
      );
  }
}
