import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetAccessToken } from '../auth/service/get-access.service';
import { environment } from '../../environments/environment';
import { ICertificate } from '../interfaces/ICertificates';

@Injectable()
export class IssueCertificateService extends TableService<ICertificate> {
  constructor(private http: HttpClient, private accessToken: GetAccessToken) {
    super(http, accessToken);
  }
  override get getURL(): string {
    return `${environment.MTLS_URL}Certificate/GetCertificates`;
  }
  override get updateURL(): string {
    return '';
  }

  override get deleteURL(): string {
    return `${environment.MTLS_URL}Certificate/RevokeCertificate`;
  }

  revoke(valueId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.patch(`${this.deleteURL}/${valueId}`, null, {
      headers: headers,
    });
  }

  issueCertificate() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(`${this.addURL}`, {
      headers: headers,
    });
  }

  override get addURL(): string {
    return `${environment.MTLS_URL}Certificate/IssueCertificate`;
  }
}
