import { Injectable } from "@angular/core";
import { TableService } from "./table.service";
import { HttpClient } from "@angular/common/http";
import { GetAccessToken } from "../auth/service/get-access.service";
import {environment} from '../../environments/environment'
import { ICertificate, IRevokeCertificate } from "../interfaces/ICertificates";

@Injectable()
export class RevokeCertificateService  extends TableService<IRevokeCertificate>  {


  constructor(private http: HttpClient,private  accessToken:GetAccessToken ) {
    super(http,accessToken);
  }
  override get getURL(): string {
    return `${environment.MTLS_URL}Certificate/GetRevokeCertificates`;
}
override get updateURL():string {
 return  `${environment.MTLS_URL}Pharmacy/UpdatePharmacy`
}

override get deleteURL(): string {
  return   `${environment.MTLS_URL}Users/DeleteUser`;
}

override get addURL(): string {
  return   `${environment.MTLS_URL}Pharmacy/CreatePharmacyAccount`;
}
}
