import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OperatorService } from 'src/app/services/operator.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'src/app/interfaces/DateFormats';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.scss'],
})
export class AddOperatorComponent implements OnInit {
  operatorForm: FormGroup;
  isEditing: boolean = false;
  userRole: string; // Assume this is set appropriately

  constructor(
    private dialogRef: MatDialogRef<AddOperatorComponent>,
    private operatorService: OperatorService
  ) {}

  ngOnInit(): void {
    this.operatorForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      nationalId: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{6,}$/),
      ]),
      // a new form control for the date of birth having default value today -18 years
      dateOfBirth: new FormControl(new Date(), [Validators.required]),
    });
  }

  save(): void {
    this.operatorForm.markAllAsTouched(); // Add this line
    if (this.operatorForm.valid) {
      this.isEditing = false; // Cancel editing
      this.operatorService
        .CreateOperator(this.operatorForm.value)
        .subscribe((res) => {
          console.log(res);
          this.dialogRef.close(); // Close the dialog
          this.operatorService.OnChange.next(true);
        });
    } else {
      Swal.fire({
        title: 'Missing fields',
        text: 'Please fill all the required fields',
        confirmButtonText: 'Ok',
      });
    }
  }

  cancel(): void {
    this.isEditing = false; // Cancel editing
    this.dialogRef.close(); // Close the dialog
  }

  edit(): void {
    this.isEditing = true;
  }

  goBack(): void {
    this.isEditing = false; // Cancel editing
    this.dialogRef.close(); // Close the dialog
  }

  updatePharmacistStatus(event: any): void {
    // Implement status update logic
  }
}
