import { Component, OnInit } from '@angular/core';
import { LandingPageService } from './services/landing-page.service';
import { CyLoginService } from './auth/service/cyLogin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'NCPeH';
  userRole = '';
  constructor(
    private authService: CyLoginService,
    private landingPageService: LandingPageService,
  ) {
    this.landingPageService.currentUserRole.subscribe((role) => {
      this.userRole = role;
    });

    this.authService.currentHasSession.subscribe((hasSession) => {
      if (hasSession) {
        this.landingPageService.getUserRole().subscribe((s) => {
          this.landingPageService.getUserProfile().subscribe((s) => {});
        });
      }
    });
  }

  ngOnInit(): void {}
}
