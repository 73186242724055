import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
  CanActivateFn,
} from '@angular/router';
import { CyLoginService } from './service/cyLogin.service';
import { map } from 'rxjs';
import { LandingPageService } from '../services/landing-page.service';

export const AuthGuard: CanActivateFn = () => {
  const isUserLoggedIn = inject(CyLoginService);
  const router = inject(Router);
  const res = isUserLoggedIn.getIsAuthenticated().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      } else {
        router.navigate(['/login']);
        return false;
      }
    })
  );
  return res;
};
