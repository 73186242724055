import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IPharmacy } from '../../../../interfaces/IPharmacy';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AddPharmacyComponent } from 'src/app/components/add-pharmacy/add-pharmacy.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pharmacies-table',
  templateUrl: './pharmacies-table.component.html',
  styleUrls: ['./pharmacies-table.component.scss'],
})
export class PharmaciesTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedRow: IPharmacy;
  displayedColumns: string[] = [
    'position',
    'pharmacyIdentityInformation',
    'uniquePharmacyIdentifier',
    'pharmacyName',
    'pharmacyPhoneNumber',
    'address',
    'addressNumber',
    'postcode',
    'provinceRegion',
    'city',
  ];
  isLoadingResults = true;
  data: any;
  resultsLength: number = 0;
  userRole: string;
  dialogSubscription: Subscription;
  constructor(
    private authService: LandingPageService,
    private dataService: PharmacyService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.authService.currentUserRole.subscribe((role) => {
      this.userRole = role;
      console.log('Role', role);
    });
  }
  ngOnInit(): void {
    this.dataService.list().subscribe((response) => {
      console.log(response);
      this.data = new MatTableDataSource(response);
      this.isLoadingResults = false;
      this.resultsLength = response.length;
      this.data.sort = this.sort;
    });
  }
  openAddPharmacyDialog(): void {
    console.log('Opening add pharmacy dialog');
    this.dialogSubscription = this.dialog.afterAllClosed.subscribe((res) => {
      this.dialogSubscription.unsubscribe();

      console.log('Dialog closed' + res);
      this.dataService.list().subscribe((response) => {
        console.log(response);
        this.data = new MatTableDataSource(response);
        this.isLoadingResults = false;
        this.resultsLength = response.length;
        this.data.sort = this.sort;
      });
    });
    this.dialog.open(AddPharmacyComponent, {
      width: '70vw',
      height: '80vh', // Adjust the size as needed
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
    console.log(filterValue);
  }

  rowClicked(row: IPharmacy) {
    this.selectedRow = row;
    console.log(row);
    this.router.navigate(['/landing-page/pharmacy-details-edit'], {
      state: row,
    });
  }
}
