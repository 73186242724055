import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { GetAccessToken } from '../auth/service/get-access.service';

export abstract class TableService<T extends Object> {
  protected constructor(
    private _http: HttpClient,
    private _accessToken: GetAccessToken,
  ) {}

  abstract get getURL(): string;

  abstract get deleteURL(): string;

  abstract get updateURL(): string;

  abstract get addURL(): string;

  list(): Observable<T[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Create an HttpParams object to hold query parameters
    let params = new HttpParams();

    return this._http
      .get<T[]>(this.getURL, {
        headers: headers,
        params: params,
      })
      .pipe(
        catchError((error) => {
          return throwError(() => new Error('Error fetching data'));
        }),
      );
  }

  get(valueId: number | string): Observable<T> {
    return this._http.get<T>(`${this.getURL}/${valueId}`);
  }

  add(value: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<T>(`${this.addURL}`, value, { headers: headers });
  }

  update(data: string | object) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.put<T>(`${this.updateURL}`, data, { headers: headers });
  }

  delete(valueId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.patch(`${this.deleteURL}?pharmacistID=${valueId}`, null, {
      headers: headers,
    });
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log(error.error.message);
    } else {
      console.log(error.error.message);
    }
    return of([]);
  }
}
