import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LandingPageService } from 'src/app/services/landing-page.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private landingPageService: LandingPageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Modify the request as needed (e.g., adding headers)
    // You can also check the request URL or method if needed
    // request = request.clone({ headers: request.headers.set(...) });

    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Successful response handling
          }
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.landingPageService.HasEror404.next(true);
              this.router.navigate(['/login']);
            }
          }
        },
      })
    );
  }
}
