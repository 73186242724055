import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PharmacistService } from 'src/app/services/pharmacist.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-pharmacist',
  templateUrl: './add-pharmacist.component.html',
  styleUrls: ['./add-pharmacist.component.scss'],
})
export class AddPharmacistComponent implements OnInit {
  pharmacistForm: FormGroup;
  isEditing: boolean = false;
  userRole: string; // Assume this is set appropriately
  pharmacist = {
    isActive: false,
    // ... other pharmacist properties
  };

  constructor(
    private dialogRef: MatDialogRef<AddPharmacistComponent>,
    private pharmacistService: PharmacistService
  ) {}

  ngOnInit(): void {
    this.pharmacistForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      idNumber: new FormControl('', [Validators.required]),
      pharmacistRegistrationNumber: new FormControl('', [Validators.required]),
      pharmacistNeededInfo: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobilePhone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{6,}$/),
      ]),
      // ... add other form controls as needed
    });
  }

  save(): void {
    this.pharmacistForm.markAllAsTouched(); // Add this line
    if (this.pharmacistForm.valid) {
      this.isEditing = false; // Cancel editing
      this.pharmacistService.add(this.pharmacistForm.value).subscribe((res) => {
        console.log(res);
        this.dialogRef.close(); // Close the dialog
      });
    } else {
      Swal.fire({
        title: 'Missing fields',
        text: 'Please fill all the required fields',
        confirmButtonText: 'Ok',
      });
    }
  }

  cancel(): void {
    this.isEditing = false; // Cancel editing
    this.dialogRef.close(); // Close the dialog
  }

  edit(): void {
    this.isEditing = true;
  }

  goBack(): void {
    this.isEditing = false; // Cancel editing
    this.dialogRef.close(); // Close the dialog
  }

  updatePharmacistStatus(event: any): void {
    // Implement status update logic
  }
}
