import { Component } from '@angular/core';
import { CyLoginService } from 'src/app/auth/service/cyLogin.service';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { environment } from '../../../environments/environment';
import { IUserProfile } from 'src/app/interfaces/IUserProfile';
import { DevelopmentService } from '../../services/development.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isDevelopmentMode = !environment.production;
  public isUserLoggedIn = this.authService.getIsAuthenticated();
  public session_id: string | undefined = '';
  public user_role: string | undefined = '';
  userProfile: IUserProfile | undefined;
  selectedRole = '';

  constructor(
    private authService: CyLoginService,
    private landingPageService: LandingPageService,
    private developmentService: DevelopmentService
  ) {
    landingPageService.userProfile.subscribe((profile) => {
      this.userProfile = profile;
    });
    authService.getSession().subscribe((res) => {
      this.session_id = res?.find((r) => r.type === 'sid')?.value;
    });
    this.landingPageService.currentUserRole.subscribe((role) => {
      this.user_role = role;
      this.selectedRole = this.user_role;
    });
    this.developmentService.OnChange.subscribe((res: any) => {
      // if true I want to refresh the page
      if (res) {
        console.log('reloading');
        console.log(res);
        window.location.reload();
      }
    });
  }
  onRoleChange() {
    // Logic to handle role change
    if (this.userProfile)
      this.developmentService
        .ChangeRole(+this.userProfile.userId, this.selectedRole)
        .subscribe((res: any) => {
          // I want to check if it's 200 response write me the code:
          console.log(res);

          console.log('Role changed to:', this.selectedRole);
          this.developmentService.OnChange.next(res.succeeded);
        });
  }
}
