import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { LandingPageService } from '../../services/landing-page.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-terms-conditions',
  standalone: true,
  imports: [MatCheckboxModule, MatButtonModule, FormsModule, CommonModule],

  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.scss',
})
export class TermsConditionsComponent {
  gdprAccepted = false;
  termsAccepted = false;
  submitAttempted = false;
  constructor(
    private router: Router,
    private authService: LandingPageService
  ) {}
  submit() {
    this.submitAttempted = true;
    if (this.gdprAccepted && this.termsAccepted) {
      this.authService.acceptTermsAndConditionsFunc().subscribe((res) => {
        console.log(res);
        this.authService.acceptTermsAndConditions.next(true);
        this.authService.currentUserRole.subscribe((role) => {
          if (role === 'administrator-neha') {
            this.router.navigate(['/landing-page/users']);
          } else if (role === 'operator') {
            this.router.navigate(['/landing-page/pharmacists']);
          } else if (role === 'operator-pharmacy') {
            console.log('navigating to pharmacies');
            this.router.navigate(['/landing-page/pharmacists']);
          } else {
            this.router.navigate(['/landing-page/certificates']);
          }
        });
      });
    }
  }
}
