import { Component, Input, OnInit } from '@angular/core';
import {
  IPhysicianDetails,
  IUserProfile,
} from 'src/app/interfaces/IUserProfile';

@Component({
  selector: 'app-physician-user-info',
  templateUrl: './physician-user-info.component.html',
  styleUrl: './physician-user-info.component.scss',
})
export class PhysicianUserInfoComponent implements OnInit {
  @Input() userProfile: IUserProfile;
  physicianDetails: IPhysicianDetails;
  specialtiesList: string = '';

  constructor() {}
  ngOnInit(): void {
    this.physicianDetails = this.userProfile.details as IPhysicianDetails;
    this.specialtiesList = this.physicianDetails.specialties
      .map((s) => s.name)
      .join(', ');
  }
}
