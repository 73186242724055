import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  IPharmacistDetails,
  IPhysicianDetails,
  IUserProfile,
} from 'src/app/interfaces/IUserProfile';
import { LandingPageService } from 'src/app/services/landing-page.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent {
  pharmacistForm: FormGroup;
  physicianForm: FormGroup;
  initialState: any;
  userProfile: IUserProfile;
  pharmacistDetails?: IPharmacistDetails;
  physicianDetails?: IPhysicianDetails;
  specialtiesList: string = '';

  constructor(
    private location: Location,
    private router: Router,
    private authService: LandingPageService
  ) {
    this.authService.userProfile.subscribe((profile) => {
      this.userProfile = profile as IUserProfile;
      if (this.userProfile.role === 'pharmacist') {
        this.pharmacistDetails = this.userProfile.details as IPharmacistDetails;
      } else if (this.userProfile.role === 'physician') {
        this.physicianDetails = this.userProfile.details as IPhysicianDetails;
        this.specialtiesList = this.physicianDetails.specialties
          .map((s) => s.name)
          .join(', ');
      }
    });
  }
  goBack() {
    this.location.back();
  }
}
