// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-not-found .page-not-found__body h1 {
  font-size: 50px;
}
.page-not-found .page-not-found__body p {
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdC1mb3VuZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSx1QkFBQTtBQUNKO0FBQUk7RUFDRSxlQUFBO0FBRU47QUFBSTtFQUNJLGVBQUE7QUFFUiIsImZpbGUiOiJub3QtZm91bmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGFnZS1ub3QtZm91bmR7XHJcbiAgICBoZWlnaHQ6IDEwMHZoO1xyXG4gICAgZGlzcGxheTogZmxleDtcclxuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcclxuICAgIC5wYWdlLW5vdC1mb3VuZF9fYm9keSAgaDF7XHJcbiAgICAgIGZvbnQtc2l6ZTogNTBweDtcclxuICAgIH1cclxuICAgIC5wYWdlLW5vdC1mb3VuZF9fYm9keSAgcHtcclxuICAgICAgICBmb250LXNpemU6IDIwcHg7XHJcbiAgICAgIH1cclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/screens/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAAI;EACE,eAAA;AAEN;AAAI;EACI,eAAA;AAER;AACA,osBAAosB","sourcesContent":[".page-not-found{\r\n    height: 100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    .page-not-found__body  h1{\r\n      font-size: 50px;\r\n    }\r\n    .page-not-found__body  p{\r\n        font-size: 20px;\r\n      }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
