import { Component, Input, OnInit } from '@angular/core';
import {
  IPharmacistDetails,
  IUserProfile,
} from 'src/app/interfaces/IUserProfile';

@Component({
  selector: 'app-pharmacist-user-info',
  templateUrl: './pharmacist-user-info.component.html',
  styleUrl: './pharmacist-user-info.component.scss',
})
export class PharmacistUserInfoComponent implements OnInit {
  @Input() userProfile: IUserProfile;
  pharmacistDetails: IPharmacistDetails;
  specialtiesList: string = '';

  constructor() {}
  ngOnInit(): void {
    this.pharmacistDetails = this.userProfile.details as IPharmacistDetails; 
  }
}
