import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable()
export class GetAccessToken {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  constructor() {}

  setToken(token: string): void {
    this.tokenSubject.next(token);
  }

  getToken(): Observable<string> {
    return this.tokenSubject.asObservable();
  }
  getToken2(): string {
    return this.tokenSubject.value;
  }
}
