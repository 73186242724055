import { Component, Inject } from "@angular/core";

import {  MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";



@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'Dialog.html',
    styleUrls: ['./dialog.scss']
    
  })
  export class DialogComponent {
    inputValues: Map<string, string> = new Map(); 
    constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
     
      this.data.forEach((item:string) => {
        this.inputValues.set(item, ''); 
      });
      
    }
    inputValueChanged(value: any, key: string): void {
      this.inputValues.set(key, value); // Update the value in the Map
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  }