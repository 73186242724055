import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IPharmacist } from 'src/app/interfaces/IPharmacist';
import { IUser } from 'src/app/interfaces/IUser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  user: IUser;
  isEditing = false;
  initialState: any;
  userForm: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dataService: UserService
  ) {
    if (router.getCurrentNavigation() == null) {
      router.navigate(['/landing-page/users']);
      return;
    }
    this.user = router.getCurrentNavigation()!.extras.state as IUser;

    this.userForm = this.formBuilder.group({
      email: [this.user.email],
      id: [this.user.id],
      idNumber: [this.user.nationalId],
      mobilePhone: ['N/A'],
      name: [this.user.name],
      surname: [this.user.surname],
      isActive: [this.user.isActive],
    });

    this.initialState = this.userForm.value;
    this.userForm.get('isActive')?.enable();
  }

  updatePharmacistStatus(event: any) {
    console.log(this.user);
    this.user.isActive = event.checked;
  }

  goBack() {
    console.log('Back button clicked!');
    this.router.navigate(['/landing-page/users']);
  }
}
