// role.guard.ts
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { LandingPageService } from '../landing-page.service';
import { OperatorGuard } from './operatorGuard';
import { OperatorPharmacyGuard } from './operatorPharmacyGuard';
import { UserGuard } from './userGuard';

@Injectable({
  providedIn: 'root',
})
export class CombinedUserOperatorGuard implements CanActivate {
  constructor(
    private operatorGuard: OperatorGuard,
    private userGuard: UserGuard
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.operatorGuard.canActivate(next, state),
      this.userGuard.canActivate(next, state),
    ]).pipe(
      map(([operatorResult, userResult]) => {
        console.log('Operator Guard Result:', operatorResult);
        console.log('User Guard Result:', userResult);
        return operatorResult || userResult;
      })
    );
  }
}
