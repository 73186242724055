import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPhysician } from 'src/app/interfaces/IPhysician';
import { PhysicianService } from 'src/app/services/physician.service';

@Component({
  selector: 'app-physician-details-view',
  templateUrl: './physician-details-view.component.html',
  styleUrl: './physician-details-view.component.scss',
})
export class PhysicianDetailsViewComponent implements OnInit {
  physician: IPhysician;
  isEditing = false;
  initialState: any;
  specialtiesList: string = '';

  constructor(
    private router: Router,
    private dataService: PhysicianService,
    private datePipe: DatePipe
  ) {
    if (router.getCurrentNavigation() == null) {
      router.navigate(['/landing-page/physicians']);
      return;
    }
    this.physician = router.getCurrentNavigation()!.extras.state as IPhysician;
  }
  ngOnInit(): void {
    this.specialtiesList = this.physician.specialties.map((s) => s).join(', ');
  }

  updatePhysicianStatus(event: any) {
    console.log(this.physician);

    this.physician.isActive = event.checked;
    this.dataService
      .ActivationPhysician(this.physician.id, this.physician.isActive)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  goBack() {
    console.log('Back button clicked!');
    this.router.navigate(['/landing-page/physicians']);
  }
}
