import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusComponent implements OnInit {
  @Input() expiryDate: string;
  @Input() isActive: boolean;
  status: string;
  currentDate = moment.utc(); // Current date in UTC
  expiryDateUTC: any; // expiryDate in UTC
  constructor() {
    // active: d.expiryDate > moment().format('YYYY-MM-DD') && d.isActive
    // expired: d.expiryDate <= moment().format('YYYY-MM-DD')
    // revoked: data.filter((d) => !d.isActive)
  }
  ngOnInit(): void {
    this.expiryDateUTC = moment.utc(this.expiryDate); // expiryDate in UTC

    console.log(this.expiryDate, this.isActive);

    this.status = !this.isActive
      ? 'Revoked'
      : this.expiryDateUTC.isBefore(this.currentDate)
      ? 'Expired'
      : 'Active';
  }
  get statusClass(): string {
    return !this.isActive
      ? 'status revoked'
      : this.expiryDateUTC.isBefore(this.currentDate)
      ? 'status expired'
      : 'status active';
  }
}
